import React from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import Container from '@material-ui/core/Container';

import Layout from '../components/layout';
import SEO from '../components/helpers/seo';

import mq from '../styles/mq';

import { ThemeType } from '../styles/theme';

const ConditionsVente = () => {
  const { color } = useTheme<ThemeType>();

  return (
    <Layout footer>
      <SEO title="Conditions générales de vente" />
      <Container>
        <section
          css={css`
            padding: 150px 0;
            min-height: 100vh;

            p {
              line-height: 1.75;
              margin-bottom: 20px;

              &:last-of-type {
                margin-bottom: 50px;
              }
            }
          `}
        >
          <h1
            css={css`
              color: ${color.accent};
              font-size: 30px;
              font-weight: 900;
              margin-bottom: 50px;
              text-align: center;

              ${mq(`xs`)} {
                font-size: 40px;
              }

              ${mq(`sm`)} {
                font-size: 50px;
              }

              ${mq(`md`)} {
                font-size: 60px;
                text-align: left;
              }
            `}
          >
            Conditions générales de vente
          </h1>
          <div>
            <p>
              1. L’ASBL « LA 3D » est spécialisée dans la promotion et le
              développement de l’économie circulaire dans le domaine de la
              production et de la consommation de la bière à un niveau local.
            </p>
            <p>
              Les présentes conditions générales sont seules applicables à tous
              nos contrats de vente de nos produits en ligne.
            </p>
          </div>
          <div>
            <p>
              2. Nos produits sont brassés par La brasserie du Renard à Pécrot.
            </p>
            <p>
              L’ASBL « LA 3D » a vocation à promouvoir et développer une
              brasserie locale qui veille à respecter certaines exigences de
              qualité (http://www.brasseriedurenard.be).
            </p>
            <p>
              Il est conseillé de conserver nos produits dans un endroit frais à
              l’abris du soleil.
            </p>
            <p>
              En ce qui concerne les conditions de dégustation de nos produits,
              l’ASBL « LA 3D » suggère à ses clients de les servir à une
              température comprise entre 3 et 6° degrés.
            </p>
          </div>
          <div>
            <p>
              3. L’utilisateur est invité à passer commande via le site
              www.la3d.be/panier (étape 1 : encodage de certaines données
              personnelles / étape 2 : sélection du produit et de la quantité
              souhaitée / étape 3 : confirmation de la commande / étape 4 :
              Paiement Tous les paiements sont sécurisés – Shopify Payments.
            </p>
            <p>
              Avant d’entamer le processus de commande de nos produits,
              l’utilisateur doit confirmer avoir atteint l’âge légal pour
              consommer des boissons alcoolisées. (À savoir, 18 ans).
            </p>
            <p>L’utilisateur peut commander en ligne 24h/24.</p>
            <p>
              Dès réception du paiement, un numéro de commande (la confirmation
              de la commande) est transmis notifiée à l’utilisateur via e-mail.
            </p>
            <p>
              A partir de la réception de ladite confirmation, l’utilisateur
              dispose d’un délai de rétraction de 14 jours (jours calendriers)
              conformément à la législation relative à la conclusion des
              contrats à distance.
            </p>
            <p>
              Dans cette dernière hypothèse, l’utilisateur sera remboursé dans
              un délai de 15 jours via son compte bancaire.
            </p>
          </div>
          <div>
            <p>
              4. Livraison Nos produits sont livrés sur l’ensemble du territoire
              belge.
            </p>
            <p>
              Nos produits commandés en ligne sont livrés dans un délai de
              10jours à partir de la date de confirmation de la commande de
              l’utilisateur.
            </p>
            <p>Nos produits sont livrés par Bpost.</p>
            <p>
              Livraison à 10 €. Livraison offerte à partir de 5 x 24 bouteilles.
              Frais de livraison si hors Belgique.
            </p>
            <p>
              Dès la réception de son colis (par lui-même ou par toute autre
              personne physique ayant réceptionné le colis pour son compte),
              l’utilisateur dispose d’un délai de 14 jours (jours calendriers)
              pour nous notifier sa volonté de se prévaloir de son droit de
              rétractation (Cette notification se transmet via
              e-mail/formulaire).
            </p>
            <p>
              Dans cette dernière hypothèse, les frais de retour sont pris en
              charge par l’ASBL « LA 3D ».
            </p>
            <p>
              Le remboursement sera effectué au plus tard 14 jours après la
              confirmation de la commande pour autant que le/les produit(s)
              retourné(s) ait/aient été reçu(s) au siège social de l’ASBL. (Ou à
              partir de la réception de preuve de renvoi du/des produits - voyez
              l’article 3 pour le processus de remboursement).
            </p>
          </div>
          <div>
            <p>
              5. L’ASBL « LA 3D » s’engage à traiter les données de ses clients
              conformément aux dispositions relatives au RGPD
              (https://www.shopify.fr/blog/ecommerce-et-rgpd).
            </p>
          </div>
          <div>
            <p>
              6. Il est strictement interdit de reproduire le présent site web
              ainsi que son contenu (logo, vidéos, textes, images, etc…).
            </p>
            <p>
              <p>
                L’ensemble des éléments constitutifs du présent site web
                appartiennent uniquement à l’ASBL « LA 3D » et sont, de ce fait,
                protégés notamment par le copyright.
              </p>
              <p>
                Chaque utilisateur a le droit de naviguer sur le site et
                d’utiliser ses fonctionnalités afin de s’informer sur nos
                produits, procéder à l’achat en ligne, nous contacter, localiser
                la liste des points de vente physiques de nos produits.
              </p>
            </p>
          </div>
          <div>
            <p>
              7. Nos prix s’entendent hors TVA. Les taux de TVA appliqués
              correspondent aux taux en vigueur au moment de l’établissement de
              la facture.
            </p>
          </div>
          <div>
            <p>
              8. En cas de litige entre l’ASBL « LA 3D » et le client
              (utilisateur) relativement aux présentes conditions générales, le
              droit belge est applicable et les juridictions du Brabant Wallon
              sont seules compétentes.
            </p>
          </div>
        </section>
      </Container>
    </Layout>
  );
};

export default ConditionsVente;
